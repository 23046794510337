@import '../../sass/mixins';

.loader-wrapper {
  position: relative;
  width: 100%;
  margin: 0;

  .loader-overlay {
    @include pulse();
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &.inverse {
    .loader-overlay {
      background-color: rgba(140, 140, 140, 0.2);
    }
  }
}
