@import '../../../sass/variables';

div.lazyImageContainer {
  img[data-is="lazy-image"] {
    z-index: 0;
    opacity: 0;
    transition: opacity $animation-duration-default ease-in;

    &.visible {
      opacity: 1;
    }
  }
}
