@value layout: "../../styles/variables/layout.module.css";
@value breakpoint-m, gap-m from layout;

.wrapper {
  margin: gap-m 0;
}

.custom-wrapper {
  position: relative;
  right: -1rem;
  left: -1rem;
  width: calc(100% + 2rem);
  padding: 1rem 1rem 0 1rem;
}

@media (width > breakpoint-m) {
  .custom-wrapper {
    right: -0.4rem;
    left: -0.4rem;
    width: calc(100% + 0.8rem);
    padding: 0.4rem 0.4rem 0 0.4rem;
  }
}
