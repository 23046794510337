@value colors: "../../../styles/variables/colors.module.css";
@value color-blue300, color-white from colors;

.button > button {
  all: revert;
  padding: 0;
  font: inherit;
  line-height: normal;
  border: none;
  background: none;
  cursor: pointer;

  min-height: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  fill: inherit;
  text-transform: inherit;
}

.button > button:focus-visible {
  border: 1px solid color-white;
  outline-offset: 2px;
  outline: 3px dashed color-blue300;
}
